<template>
    <div>
        <div class="header">
            <span class="header_txt">账号设置</span>
        </div>
        <div class="content">
            <Form :model="formItem" :label-width="140" style="width: 500px;margin-top: 20px">
                <FormItem label="账号：">
                    <Input v-model="formItem.account" disabled></Input>
                </FormItem>
                <FormItem label="连锁简称：">
                    <Input v-model="formItem.name" maxlength="5" show-word-limit></Input>
                </FormItem>
                <FormItem label="提现开关：">
                    <i-switch v-model="formItem.is_tx" @on-change="change" :true-value="1" :false-value="0"></i-switch>
                </FormItem>
                <FormItem label="连锁logo:">
                    <!-- upload -->
                    <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        :max-size="2048"
                        :on-format-error="handleFormatError"
                        :on-success="logoSuccess"
                        :on-progress="handleProgress"
                        :headers="headers"
                        :action="uploadImg"
                        >
                        <div class="form_upload">
                            <img v-if="formItem.logo_id" :src="formItem.logo.path" alt="" class="form_img">
                            <Icon v-else style="font-size:30px;" type="md-add" />
                        </div>
                    </Upload>
                </FormItem>
                <FormItem label="公众号二维码:">
                    <!-- upload -->
                    <Upload
                        ref="upload"
                        :show-upload-list="false"
                        :format="['jpg', 'jpeg', 'png', 'gif']"
                        :max-size="2048"
                        :on-format-error="handleFormatError"
                        :on-success="qrcodeSuccess"
                        :on-progress="handleProgress"
                        :headers="headers"
                        :action="uploadImg"
                        >
                        <div class="form_upload">
                            <img v-if="formItem.qrcode_id" :src="formItem.qrcode.path" alt="" class="form_img">
                            <Icon v-else style="font-size:30px;" type="md-add" />
                        </div>
                    </Upload>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="addBtn">确认修改</Button>
                </FormItem>
            </Form>
        </div>
    </div>
</template>

<script>
import { getCookies } from "@/utils/helper";
import config from "@/config/index";
import {otherModify, otherGetInfo} from '@/api/index'
    export default {
        data() {
            return {
                uploadImg: config.apiUrl + "/admin/other/uploads",
                headers: {
                    "meiye-admin-api": getCookies("meiye-admin-api"),
                },
                formItem: {
                    name: '',
                    logo_id: '',
                    logo: {},
                    account: '',
                    is_tx: 0,
                    qrcode_id: '',
                    qrcode: {path:''}
                },
            }
        },
        mounted(){
            this.otherGetInfo()
        },
        methods: {
            change (status) {
                // this.$Message.info('开关状态：' + status);
            },
            // 获取账号信息
            otherGetInfo(){
                otherGetInfo().then(res => {
                    console.log('获取',res.data)
                    this.formItem = res.data
                })
            },
            addBtn(){
                let data = {
                    name: this.formItem.name,
                    logo_id: this.formItem.logo_id,
                    is_tx: this.formItem.is_tx,
                    qrcode_id: this.formItem.qrcode_id
                }
                otherModify(data).then(res=>{
                    this.$Message.success(res.msg)
                    this.otherGetInfo()
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            //文件上传类型错误
            handleFormatError() {
                this.$Message.warning("暂不支持上传此格式");
            },
            // 文件上传时
            handleProgress() {
                const msg = this.$Message.loading({
                    content: "上传中...",
                    duration: 0,
                });
                setTimeout(msg, 3000);
            },
            // logo上传
            logoSuccess(e) {
                this.formItem.logo_id = e.data.id
                this.formItem.logo.path = e.data.path
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
            // 二维码
            qrcodeSuccess(e){
                this.formItem.qrcode_id = e.data.id
                this.formItem.qrcode.path = e.data.path
                this.$Message.destroy();
                this.$Message.success(e.msg);
            }
        },
    }
</script>

<style lang="scss" scoped>
.header{
    width: 100%;
    height: 55px;
    padding: 16px;
    background-color: #cee0f9;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    display: flex;
    align-items: center;
}
.header_txt{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.content{
    margin: 20px 0;
}
.form_upload{
    width: 200px;
    height:200px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #c0c0c0;
    border-radius: 10px;
}
.form_img{
    width: 200px;
    height:200px;
    border-radius: 10px;
}
.form_img:hover{
    border: 1px dashed #409eff;
}
</style>